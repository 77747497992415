import ko from 'knockout';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { ChartState, ICompleteChartSeries, GraphType, ChartAxis, AmChart, PromiseIndicator, ITrendLinks, newAxisOption } from '@/apps/timeSeriesViewer';
import { IKoDropOptions } from '@/gr/common/knockout/bindings/drop';
import { KnockoutVueComponent } from '@/common/knockout-vue-binding';
import GrColourPickerWrapper from '@/components/GrColourPickerWrapper.vue';
import { ImageSaver, Tools } from '@/services';

const dropClasses = 'drop-theme-arrows chart-editor';

class ChartSeriesEditor {
  constructor(
    private _series: ICompleteChartSeries,
    private _chartState: ChartState
  ) {
    this._dataPeriod = this._series.configuredDataDefinition.dataSummary?.period;
    this.name = this._series.configuredDataDefinition.displayName;
    this.nameOrDefault = this._series.configuredDataDefinition.displayNameOrDefault;
    this.cssClass = ko.computed(() => ChartSeriesEditor.getCssClass(this._series.type()));
    this.color = this._series.color;
    this.type = this._series.type;
    this.showInLegend = this._series.showInLegend;
    this.axis = this._series.axis;
    this.maxColumnWidth =
      this._dataPeriod && this._dataPeriod.asMilliseconds() !== 0 && this._chartState.minPeriod.asMilliseconds() !== 0
        ? this._dataPeriod.asMilliseconds() / this._chartState.minPeriod.asMilliseconds()
        : 1;
    this.columnWidthStep = Math.ceil(this.maxColumnWidth * 0.1);
    this.columnWidth = ko.pureComputed({ read: () => this._series.columnWidth() || Math.ceil(this.maxColumnWidth / 2), write: (value) => this._series.columnWidth(value) });
    this.columnWidthPercentage = ko.pureComputed(() => Math.floor((this.columnWidth() / this.maxColumnWidth) * 100) + '%');
    this.isColumnWidthApplicable = this._series.isColumnWidthApplicable;
    this.isStacked = this._series.isStacked;
    this.isStackedApplicable = this._series.isStackedApplicable;
    this.isStepped = this._series.isStepped;
    this.isSteppedApplicable = this._series.isSteppedApplicable;
    this.lineThickness = this._series.lineThicknessWithDefault;
    this.isLineThicknessApplicable = this._series.isLineThicknessApplicable;

    this.grColourPickerWrapper = new KnockoutVueComponent(GrColourPickerWrapper, this.grColourPickerWrapperProps());
    this.selectedAxis = ko.pureComputed({
      read: () => this._series.axis()?.id ?? '',
      write: (value) => this._chartState.moveSeriesToAxis(this._series.id, value)
    });
    this.axisOptions = ko.pureComputed(() =>
      this._chartState
        .axes()
        .filter((a) => a.unitsOfMeasure().id === this._series.configuredDataDefinition.unitsOfMeasure?.id)
        .map((a) => ({ id: a.id, description: ko.pureComputed(() => `to the axis: ${a.description()}`) }))
        .concat([newAxisOption])
    );
  }

  private _dataPeriod;
  name;
  nameOrDefault;
  cssClass: KnockoutComputed<string>;
  color;
  type;
  showInLegend;
  axis;
  maxColumnWidth;
  columnWidthStep;
  columnWidth: KnockoutComputed<number>;
  columnWidthPercentage: KnockoutComputed<string>;
  isColumnWidthApplicable;
  isStacked;
  isStackedApplicable;
  isStepped;
  isSteppedApplicable;
  lineThickness;
  isLineThicknessApplicable;
  grColourPickerWrapper: KnockoutVueComponent;
  grColourPickerWrapperProps = ko.computed(() => {
    return {
      propsData: {
        value: this._series.color().startsWith('rgb') ? Tools.rgbaToHash(this._series.color()) : `${this._series.color()}${this._series.color().length === 7 ? 'FF' : ''}`,
        kOInputCallback: (colour: string) => this.changeSeriesColour({ value: colour })
      }
    };
  });

  drop: IKoDropOptions = { template: 'series-editor-template', position: 'right middle', constrainToScrollParent: false, keepOpenTargets: ['[data-role="colorpicker"]'], classes: dropClasses };

  changeSeriesColour = (e: { value: string }) => {
    // this hack of removing the alpha if FF is needed to maintain compatibility with chromium v57 used in ez2view v7
    if (e.value.length === 9 && e.value.substr(7) === 'FF') this._series.color(e.value.substr(0, 7));
    else this._series.color(e.value);
  };

  selectedAxis: KnockoutComputed<string>;
  axisOptions: KnockoutComputed<{ id: string; description: KnockoutComputed<string> }[]>;

  private static getCssClass(type: GraphType): string {
    switch (type) {
      case 'line':
        return 'fa-chart-line';
      case 'column':
        return 'fa-chart-bar';
      case 'area':
        return 'fa-chart-area';
    }
  }
}

class ChartAxisEditor {
  name: KnockoutComputed<string>;
  axisDynamic: KnockoutComputed<boolean>;
  axisLinear: KnockoutComputed<boolean>;
  max;
  min;
  title;
  dataMax;
  dataMin;
  position;
  description;
  drop: IKoDropOptions = { template: 'axis-editor-template', position: 'right middle', constrainToScrollParent: false, classes: dropClasses };

  constructor(private _axis: ChartAxis) {
    this.name = ko.pureComputed(() => this._axis.unitsOfMeasure().displayName);
    this.axisDynamic = ko.pureComputed({ read: () => !this._axis.axisFixed(), write: (value) => this._axis.axisFixed(!value) });
    this.axisLinear = ko.pureComputed({ read: () => !this._axis.axisLogarithmic(), write: (value) => this._axis.axisLogarithmic(!value) });
    this.max = this._axis.max;
    this.min = this._axis.min;
    this.title = this._axis.title;
    this.dataMax = this._axis.dataMax;
    this.dataMin = this._axis.dataMin;
    this.position = this._axis.position;
    this.description = this._axis.description;
  }
}

export class Component {
  series = ko.pureComputed(() => this._args.state.series().map((s) => new ChartSeriesEditor(s, this._args.state)));
  axes = ko.pureComputed(() => this._args.state.axes().map((a) => new ChartAxisEditor(a)));
  hasTitle;
  hasLegend;
  hasScrollbar;
  hasTooltips;
  theme;
  showTooltipsForNextAvailable;
  alignZeroOnYAxes;
  downloadImage = new PromiseIndicator.ArgsFactory().create(
    () => ImageSaver.saveAsImage(document.getElementsByClassName('am-chart screenshotable')[0] as HTMLElement, this._args.state.title() ?? undefined),
    () => 'Failed to download image'
  );

  constructor(private _args: Args) {
    this.hasTitle = this._args.state.hasTitle;
    this.hasLegend = this._args.state.hasLegend;
    this.hasScrollbar = this._args.state.hasScrollBar;
    this.hasTooltips = this._args.state.hasTooltips;
    this.theme = this._args.state.theme;
    this.showTooltipsForNextAvailable = this._args.state.showTooltipsForNextAvailable;
    this.alignZeroOnYAxes = this._args.state.alignZeroOnYAxes;
  }
}

export class Args {
  constructor(
    public state: ChartState,
    public chartFunctions: AmChart.IChartFunctions,
    public links: ITrendLinks
  ) {}
}

export class ArgsFactory {
  constructor(private _links: ITrendLinks) {}

  create(state: ChartState, chartFunctions: AmChart.IChartFunctions): Args {
    return new Args(state, chartFunctions, this._links);
  }
}

import html from './configureChartComponent.html';
defineComponent(() => Component, 'configureChart', html);
require('./configureChartComponent.less');
