import moment from 'moment';
import ko from 'knockout';
import { UnitsOfMeasure, InputDataDefinition, TimeStep, Aggregate, CalculatedMetric, RawMetric, AggregateMetric } from '@/apps/timeSeriesViewer';

export interface ICompleteConfiguredDataDefinition extends ConfiguredDataDefinition {
  unitsOfMeasure: UnitsOfMeasure;
  dataSummary: DataSummary;
}

export class ConfiguredDataDefinition {
  constructor(
    readonly id: string,
    readonly calculationVariableName: string,
    readonly input: InputDataDefinition
  ) {
    this.timeStep('Raw');
    this.aggregate('None');
  }

  private _timeStep = ko.observable<TimeStep>();
  private _aggregate = ko.observable<Aggregate>();

  displayName = ko.observable<string>();

  displayNameOrDefault = ko.pureComputed(() => {
    const displayName = this.displayName();
    if (displayName) return displayName;

    if (this.input.id instanceof CalculatedMetric) return this.input.id.expression() || 'Empty Calculation';

    return this.input.name();
  });

  timeStep: KnockoutObservable<TimeStep | undefined> = ko.pureComputed({
    read: () => this._timeStep(),
    write: (value) => {
      this._timeStep(value);
      if (value === 'Raw') {
        this._aggregate('None');
      } else if (this._aggregate() === 'None') {
        this._aggregate('Average');
      }
    }
  });

  aggregate: KnockoutObservable<Aggregate | undefined> = ko.pureComputed({
    read: () => this._aggregate(),
    write: (value) => {
      if (this.timeStep() !== 'Raw' && value !== 'None') this._aggregate(value);
    }
  });

  requiresAggregate = ko.pureComputed<boolean>(() => this.timeStep() !== 'Raw');
  unitsOfMeasure!: UnitsOfMeasure | null;
  dataSummary!: DataSummary | null;
  isIncludedInOutputChannels = ko.observable(true);

  equals(other: ConfiguredDataDefinition): boolean {
    return this.id === other.id;
  }

  isAffectedByRelease(): boolean {
    const metrics = ['EnergyGeneratedEstimate', 'FinalMWGeneration', 'InitialMWGeneration'];
    const metric = this.input.id;

    if (metric instanceof RawMetric) {
      const rawMetric = metric as RawMetric;
      if (metrics.find((x) => rawMetric.id.includes(x)) && rawMetric.id.includes('Generators') && loadIds.find((x) => rawMetric.id.includes(x))) {
        return true;
      }
    }

    if (metric instanceof AggregateMetric) {
      const aggregateMetric = metric as AggregateMetric;
      if (
        metrics.includes(aggregateMetric.propertyId) &&
        // if you haven't excluded loads in using a facet, show warning
        !aggregateMetric.facets.some(
          (f) =>
            // load type aggregations of Generators have excluded loads and are OK
            (f.facetId === 'LoadType' && f.valueId === 'Generation') ||
            // fuel type aggregations that aren't Electricity have excluded loads and are OK
            (f.facetId === 'FuelType' && f.valueId !== 'Electricity')
        )
      )
        return true;
    }

    return false;
  }
}

export class DataSummary {
  constructor(readonly period: moment.Duration | null) {}
}

// Used by the checkIfEffected method to determine if the trend has been effected by a change in the data definitions
const loadIds = [
  'ADPBA1L',
  'APD01',
  'APD02',
  'AS_NSW1',
  'ASENGY01',
  'ASNACTW1',
  'ASNAES1',
  'ASNBRL1',
  'ASNENC1',
  'ASNSEL2',
  'ASPAPM01',
  'ASQEEV1',
  'ASQENC1',
  'ASRMGE01',
  'ASRMGE02',
  'ASRMGE03',
  'ASSDE1',
  'ASSEL1',
  'ASSENC1',
  'ASSERDV1',
  'ASSSN1',
  'ASTFG1V1',
  'ASTHYD1',
  'ASVIEL2',
  'BALBL1',
  'BBATRYL1',
  'BHASZINC',
  'BHBL1',
  'BOWWBA1L',
  'BULBESL1',
  'CAPBES1L',
  'CBWWBA1L',
  'CHBESSL1',
  'DALNTHL1',
  'DPNTBL1',
  'DRACAN1',
  'DRACAV1',
  'DRAESN1',
  'DREXMN01',
  'DRFIIN01',
  'DRFIIN02',
  'DRGBND01',
  'DRGBND02',
  'DROZVP01',
  'DRSHVN01',
  'DRSHVS01',
  'DRVIOT01',
  'DRVIOT02',
  'DRVIOT03',
  'DRVIOT04',
  'DRVIOT05',
  'DRXNDA01',
  'DRXNDA02',
  'DRXNDA03',
  'DRXNDA04',
  'DRXNDE01',
  'DRXNQX01',
  'DRXQDA01',
  'DRXQQE01',
  'DRXSQS01',
  'DRXVDJ01',
  'DRXVDP01',
  'DRXVDX01',
  'DRXVQP01',
  'DRXVQX01',
  'DRXVQX02',
  'ERG_AS',
  'GANNBL1',
  'HBESSL1',
  'HPRL1',
  'HVWWBA1L',
  'KEPBL1',
  'LBBL1',
  'PIBESSL1',
  'PTH01',
  'PTH02',
  'PTH03',
  'PUMP1',
  'PUMP2',
  'QBYNBL1',
  'RESS1L',
  'RIVNBL2',
  'RT_NSW1',
  'RT_NSW2',
  'RT_NSW3',
  'RT_NSW4',
  'RT_NSW5',
  'RT_NSW6',
  'RT_QLD1',
  'RT_SA1',
  'RT_SA2',
  'RT_SA3',
  'RT_SA4',
  'RT_SA5',
  'RT_SA6',
  'RT_TAS1',
  'RT_VIC1',
  'RT_VIC10',
  'RT_VIC11',
  'RT_VIC12',
  'RT_VIC2',
  'RT_VIC3',
  'RT_VIC4',
  'RT_VIC5',
  'RT_VIC6',
  'RT_VIC7',
  'RT_VIC8',
  'RT_VIC9',
  'SHPUMP',
  'SLDCBLK1',
  'SNOWYP',
  'TB2BL1',
  'TIBL1',
  'TOMAGO1',
  'TOMAGO2',
  'TOMAGO3',
  'VBBL1',
  'VENUS1',
  'VICSMLT',
  'VICSMLT2',
  'VSNEL2S1',
  'VSNSN1V1',
  'VSQHT1V1',
  'VSSAE1V1',
  'VSSEL1V1',
  'VSSSE1V1',
  'VSSSH1S1',
  'VSVEL2S1',
  'WALGRVL1',
  'WANDBL1'
];
