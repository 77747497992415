import ko from 'knockout';
import moment from 'moment';
import $ from 'jquery';
import { defineComponent } from '@/gr/common/knockout/defineComponent';
import { TrendServices, TimeController } from '@/apps/timeSeriesViewer';
import AmCharts from '@/libs/amcharts';
import '@/libs/bootstrap-contextmenu';

export class Component {
  chartCursorTime = ko.observable<moment.Moment>();

  constructor(
    private _args: Args,
    element: Node
  ) {
    if (!_args.hostTimeController.canJumpToTime) return;

    if (_args.chart.chartCursor.addListener) {
      _args.chart.chartCursor.addListener('changed', (event) => {
        if (event.index !== undefined) this.chartCursorTime(moment(event.chart.dataProvider[event.index].timeStamp));
      });
    }

    $(_args.chartElement).contextmenu(
      {
        $menu: $(element as HTMLElement).find('.chart-context-menu'),
        before: () => {
          return this;
        }
      },
      () => {
        // do nothing
      }
    );
  }

  jumpToTime = async (): Promise<void> => {
    const CCT = this.chartCursorTime();
    if (CCT) await this._args.hostTimeController.jumpToTime(CCT);
  };
}

export class Args {
  constructor(
    public chart: AmCharts.AmSerialChart,
    public chartElement: HTMLElement,
    public hostTimeController: TimeController
  ) {}
}

export class ArgsFactory {
  constructor(private _services: TrendServices) {}

  create(chart: AmCharts.AmSerialChart, chartElement: HTMLElement): Args {
    return new Args(chart, chartElement, this._services.timeController);
  }
}

import html from './amChartRightClickMenuComponent.html';
defineComponent(() => Component, 'amChartRightClickMenu', html);
